<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="600" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          {{ isEdit ? 'Редактирование шаблона расписания' : 'Добавление шаблона расписания' }}
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <v-textarea
                  v-model="scheduleTemplateLocal.description"
                  label="Наименование"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.description"
                  @input="$v.scheduleTemplateLocal.description.$touch()"
                  @blur="$v.scheduleTemplateLocal.description.$touch()"
                />

                <v-checkbox
                  v-model="defaultEventPlaceCheckbox"
                  class="default-event-place"
                  label="Указать одну площадку для всех занятий шаблона"
                  hide-details
                />

                <v-autocomplete
                  v-show="defaultEventPlaceCheckbox"
                  v-model="defaultEventPlace"
                  label="Площадка проведения занятий"
                  placeholder="Выберите значение из списка"
                  :items="eventPlaces.list"
                  item-text="fullAddress"
                  hide-no-data
                  return-object
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="disableSaveBtn"
						:loading="loading"
            color="success"
            text
            @click="saveScheduleTemplate"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { eventPlaceApi } from '@/api'
import { mapGetters } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import { required, maxLength } from 'vuelidate/lib/validators'
import { setValidateMessages } from '@/scripts/validation'
import { ScheduleTemplate, EventPlaces, EventPlace } from '@/models'

export default {
  name: 'EventPlaceDialog',

	props: {
		dialog: Boolean,
    scheduleTemplate: Object,
		loading: Boolean
	},

  async created() {
    await this.getEventPlaces()
  },

	data: () => ({
    defaultEventPlaceCheckbox: false,
    defaultEventPlace: null,
    eventPlaces: new EventPlaces(),

    scheduleTemplateLocal: new ScheduleTemplate()
	}),

  validations() {
    return {
      scheduleTemplateLocal: {
        description: { required, maxLength: maxLength(300) }
      }
    }
  },

	computed: {
    ...mapGetters('department', ['departmentId']),

    isEdit() {
      return this.scheduleTemplateLocal?.scheduleTemplateId
    },

    disableSaveBtn() {
      return this.$v.$invalid || (this.defaultEventPlaceCheckbox && !this.defaultEventPlace)
    },

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.scheduleTemplateLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.scheduleTemplateLocal[key])
      })

      return allErrors
    }
	},

	methods: {
    async getEventPlaces() {
      try {
        const data = await eventPlaceApi.getEventPlaces(this.departmentId)
        this.eventPlaces.buildFromAPI(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    saveScheduleTemplate() {
      const data = {
        scheduleTemplate: _cloneDeep(this.scheduleTemplateLocal),
        defaultEventPlaceCheckbox: this.defaultEventPlaceCheckbox,
        defaultEventPlace: this.defaultEventPlace
      }

      this.$emit('saveScheduleTemplate', data)
    },

    resetModal() {
      this.$v.$reset()
      this.defaultEventPlaceCheckbox = false
      this.defaultEventPlace = null
      this.scheduleTemplateLocal = new ScheduleTemplate()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    scheduleTemplate(val) {
      if (val) {
        this.scheduleTemplateLocal = ScheduleTemplate.buildFromAPI(val)
        const defaultEventPlace = localStorage.getItem(`schTemp-${this.scheduleTemplateLocal.scheduleTemplateId}`)

        if (defaultEventPlace) {
          this.defaultEventPlace = EventPlace.buildFromAPI(JSON.parse(defaultEventPlace))
          this.defaultEventPlaceCheckbox = true
        }
      } else {
        this.scheduleTemplateLocal = new ScheduleTemplate()
      }
    },

    dialog(val) {
      !val && this.resetModal()
    },

    defaultEventPlaceCheckbox(val) {
      if (!val) this.defaultEventPlace = null
    }
  }
}
</script>

<style scoped>
.default-event-place {
  margin: 0 0 12px -2px;
  padding-top: 0;
}
</style>