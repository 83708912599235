<template>
  <div v-show="dataLoaded">
    <v-row>
      <v-col cols="12" class="pt-0 mt-3">
        <v-btn
          small
          color="primary"
          class="white--text px-4 mr-3"
          @click="dialog = true"
        >
          <v-icon left small>mdi-plus</v-icon>Добавить
        </v-btn>

        <v-btn
          :loading="templateExcelLoading"
          small
          color="primary"
          class="white--text px-4"
          @click="getScheduleTemplateExcel"
        >
          <v-icon left size="20">mdi-file-download-outline</v-icon>Получить файл для заполнения
        </v-btn>

        <v-card
          v-show="scheduleTemplates.list.length"
          class="table-card mt-4"
        >
          <v-data-table
            ref="scheduleTemplatesTable"
            :loading="tableLoading"
            class="base-table"
            fixed-header
            hide-default-footer
            :headers="headers"
            :items="scheduleTemplates.list"
            :items-per-page="-1"
          >
            <template #item.description="{ item }">
              <router-link class="link-wrapper" :to="`/department/schedule-template/${item.scheduleTemplateId}`">
                <span class="link">{{ item.description || '-' }}</span>
              </router-link>
            </template>

            <template #item.actions="{ item }">
              <v-icon
                color="btnIcon"
                class="mr-2 copy-btn"
                size="20"
                title="Создать копию шаблона расписания"
                @click="copyScheduleTemplate(item)"
              >
                mdi-content-copy
              </v-icon>

              <v-icon
                color="btnIcon"
                class="mr-1 edit-btn"
                size="22"
                title="Редактировать"
                @click="editScheduleTemplate(item)"
              >
                mdi-pencil-outline
              </v-icon>

              <v-icon
                color="btnIcon"
                class="remove-btn"
                size="22"
                title="Удалить"
                @click="confirmRemoveScheduleTemplate(item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </template>

            <template slot="no-data">
              <div>Таблица пуста</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <ScheduleTemplateDialog
      :dialog="dialog"
      :scheduleTemplate="selectedItem"
      :loading="scheduleTemplateLoading"
      @saveScheduleTemplate="saveScheduleTemplate"
      @closeDialog="closeDialog"
    />

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :loading="deleteLoading"
      :message="'Вы уверены, что хотите удалить шаблон расписания?'"
      @confirm="removeScheduleTemplate"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { scheduleApi, reportApi } from '@/api'
import { mapGetters } from 'vuex'
import download from '@/plugins/download'
import { ScheduleTemplates } from '@/models'
import ScheduleTemplateDialog from '@/components/department/scheduleTemplates/dialogs/ScheduleTemplate'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: 'ScheduleTemplatesTable',

  components: {
    ScheduleTemplateDialog,
    DeleteConfirmDialog
  },

  async created() {
    await this.getScheduleTemplates()
    this.dataLoaded = true
    this.$emit('dataLoaded')
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Наименование', value: 'description', sortable: false, align: 'left' },
      { text: 'Количество строк', value: 'linesCount', sortable: false, align: 'center' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '130px' }
    ],
    selectedItem: null,
    dialog: false,
    confirmDialog: false,
    tableLoading: false,
    scheduleTemplateLoading: false,
    templateExcelLoading: false,
    deleteLoading: false,

    scheduleTemplates: new ScheduleTemplates()
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId'])
  },

  methods: {
    async getScheduleTemplates() {
      try {
        const data = await scheduleApi.getScheduleTemplatesList(this.departmentId)
        data.length && this.scheduleTemplates.buildFromAPI(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveScheduleTemplate({ scheduleTemplate, defaultEventPlaceCheckbox, defaultEventPlace }) {
      try {
        this.scheduleTemplateLoading = true
        const body = { description: scheduleTemplate.description }

        if (scheduleTemplate.scheduleTemplateId) {
          await scheduleApi.renameScheduleTemplate(scheduleTemplate.scheduleTemplateId, body)
          this.scheduleTemplates.updateScheduleTemplate(scheduleTemplate)

          // update in LocalStorage
          if (defaultEventPlaceCheckbox && defaultEventPlace) {
            localStorage.setItem(`schTemp-${scheduleTemplate.scheduleTemplateId}`, JSON.stringify(defaultEventPlace))
          }

          // remove in LocalStorage
          if (!defaultEventPlaceCheckbox && localStorage.getItem(`schTemp-${scheduleTemplate.scheduleTemplateId}`)) {
            localStorage.removeItem(`schTemp-${scheduleTemplate.scheduleTemplateId}`)
          }
        } else {
          const data = await scheduleApi.createScheduleTemplate(this.departmentId, body)
          this.scheduleTemplates.addScheduleTemplate(data)

          // create in LocalStorage
          if (data.scheduleTemplateId && defaultEventPlace) {
            localStorage.setItem(`schTemp-${data.scheduleTemplateId}`, JSON.stringify(defaultEventPlace))
          }
        }
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения шаблона расписания')
      } finally {
        this.scheduleTemplateLoading = false
      }
    },

    async copyScheduleTemplate(scheduleTemplate) {
      try {
        this.tableLoading = true

        if (scheduleTemplate.scheduleTemplateId) {
          const data = await scheduleApi.copyScheduleTemplate(scheduleTemplate.scheduleTemplateId)
          this.scheduleTemplates.addScheduleTemplate(data)
          this.$toastr('success', '', 'Копия шаблона расписания создана')
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка копирования шаблона расписания')
      } finally {
        this.tableLoading = false
      }
    },

    async removeScheduleTemplate() {
      try {
        if (this.selectedItem.scheduleTemplateId) {
          this.deleteLoading = true

          await scheduleApi.removeScheduleTemplate(this.selectedItem.scheduleTemplateId)
          this.scheduleTemplates.removeScheduleTemplate(this.selectedItem.scheduleTemplateId)
          localStorage.removeItem(`schTemp-${this.selectedItem.scheduleTemplateId}`)
          this.closeConfirmDialog()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления шаблона расписания')
      } finally {
        this.deleteLoading = false
      }
    },

    async getScheduleTemplateExcel() {
      try {
        this.templateExcelLoading = true

        const data = await reportApi.getScheduleTemplateExcel()
        data && download(data, 'Шаблон расписания.xlsx')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка генерации шаблона расписания')
      } finally {
        this.templateExcelLoading = false
      }
    },

    setTableHeight() {
      const otherElements = 299 + this.techMessageHeight

      if (this.$refs?.scheduleTemplatesTable) {
        this.$refs.scheduleTemplatesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    editScheduleTemplate(item) {
      this.selectedItem = item
      this.dialog = true
    },

    confirmRemoveScheduleTemplate(item) {
      this.selectedItem = item
      this.confirmDialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    }
  }
}
</script>