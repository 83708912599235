<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="430" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="title accent--text pb-4">
          <span>{{ cycleAccessTypeName }}</span>
        </v-card-title>

				<v-card-text outlined tile class="border-top cycle-access-content">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 px-0">
                <v-radio-group
                  v-model="cycleAccessLineLocal.before"
                  class="pt-0 mt-0"
                  row
                  hide-details
                >
                  <span class="label-span accent--text">Действие по времени</span>
                  <v-radio class="accent--text" label="До даты" :value="true" />
                  <v-radio class="accent--text" label="После даты" :value="false" />
                </v-radio-group>

                <v-spacer class="my-3" />

                <v-radio-group
                  v-model="cycleAccessLineLocal.dateModeAbsolute"
                  class="pt-0 mt-0"
                  row
                  hide-details
                >
                  <span class="label-span accent--text">Ограничение по времени</span>
                  <v-radio class="accent--text" label="Абсолютное" :value="true" />
                  <v-radio class="accent--text" label="Относительное" :value="false" />
                </v-radio-group>

                <v-spacer class="my-3" />

                <div class="date-mode mt-4" v-if="cycleAccessLineLocal.dateModeAbsolute">
                  <DatePicker
                    :date="cycleAccessLineLocal.enableTo"
                    label="Дата с"
                    @setDate="cycleAccessLineLocal.enableTo = $event"
                  />
                </div>

                <div class="date-mode" v-else>
                  <v-radio-group
                    v-model="cycleAccessLineLocal.begin"
                    class="pt-0 mt-0"
                    row
                    hide-details
                  >
                    <span class="label-span accent--text">Относительно даты цикла</span>
                    <v-radio class="accent--text" label="Начало" :value="true" />
                    <v-radio class="accent--text" label="Завершение" :value="false" />
                  </v-radio-group>

                  <v-text-field
                    v-model="cycleAccessLineLocal.days"
                    class="mt-3"
                    label="Значение"
                    placeholder="0-99"
                    v-mask="'##'"
                    :error-messages="errorMessages.days"
                    @input="$v.cycleAccessLineLocal.days.$touch()"
                    @blur="$v.cycleAccessLineLocal.days.$touch()"
                  />
                </div>
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="saveCycleAccess"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { requiredIf } from 'vuelidate/lib/validators'
import { regexFrom0To99, setValidateMessages } from '@/scripts/validation'
import { CycleAccessLine } from '@/models'
import DatePicker from '@/components/ui/DatePicker'

export default {
  name: 'CycleAccessLineDialog',

  components: {
    DatePicker
  },

	props: {
		dialog: Boolean,
    cycleAccessLine: Object,
		loading: Boolean
	},

	data: () => ({
    cycleAccessLineLocal: new CycleAccessLine()
	}),

  validations() {
    return {
      cycleAccessLineLocal: {
        days: { required: requiredIf(() => !this.cycleAccessLineLocal.dateModeAbsolute), regexFrom0To99 },
        enableTo: { required: requiredIf(() => this.cycleAccessLineLocal.dateModeAbsolute) }
      }
    }
  },

	computed: {
    cycleAccessTypeName() {
      return this.cycleAccessLineLocal.cycleAccessParamType?.name
    },

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.cycleAccessLineLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.cycleAccessLineLocal[key])
      })

      return allErrors
    }
	},

	methods: {
    saveCycleAccess() {
      this.$emit('saveCycleAccess', _cloneDeep(this.cycleAccessLineLocal))
    },

    resetModal() {
      this.$v.$reset()
      this.cycleAccessLineLocal = new CycleAccessLine()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    cycleAccessLine(val) {
      this.cycleAccessLineLocal = val ? _cloneDeep(val) : new CycleAccessLine()
    },

    dialog(val) {
      !val && this.resetModal()
    }
  }
}
</script>

<style scoped>
.cycle-access-content {
  min-height: 323px;
  max-height: 323px;
}

.label-span {
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--accent-color) !important;
}

.dialog-card >>> .v-card__text .spacer {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.date-mode {
  max-width: 265px;
}
</style>