import _cloneDeep from 'lodash/cloneDeep'
import { eventBus } from '@/main'
import { AcademicWork, EventPlace } from '@/models'

export class ScheduleTemplates {
  constructor() {
    this.list = []
  }

  addScheduleTemplate(scheduleTemplate) {
    this.list.push(ScheduleTemplate.buildFromAPI(scheduleTemplate))
  }

  updateScheduleTemplate(scheduleTemplate) {
    const index = this.list.findIndex(el => el.scheduleTemplateId === scheduleTemplate.scheduleTemplateId)
    this.list.splice(index, 1, ScheduleTemplate.buildFromAPI(scheduleTemplate))
  }

  removeScheduleTemplate(scheduleTemplateId) {
    const index = this.list.findIndex(el => el.scheduleTemplateId === scheduleTemplateId)
    this.list.splice(index, 1)
  }

  getForAPI() {
    return this.list.map(scheduleTemplate => scheduleTemplate.getForAPI())
  }

  buildFromAPI(obj) {
    if (obj) {
      this.list = obj.map(scheduleTemplate => ScheduleTemplate.buildFromAPI(scheduleTemplate))
    }

    return this
  }
}


export class ScheduleTemplate {
  constructor() {
    this.scheduleTemplateId = null
    this.departmentId = null
    this.description = null
    this.linesCount = null
    this.lines = []

    this.validationErrors = {
      dayNumber: []
    }

    this.tableKey = Math.random()
    this.hasAnyChanges = false
  }

  clearValidationErrors() {
    this.validationErrors = {
      dayNumber: []
    }
  }

  sortLinesByOrder() {
    this.lines.sort((a, b) => a.rowOrder - b.rowOrder)
  }

  setRowOrders() {
    this.lines.forEach((line, index) => line.rowOrder = index + 1)
  }

  reRenderTable() {
    this.tableKey = Math.random()
  }

  isSingleLineOnDay(line) {
    return this.lines.filter(el => el.dayNumber === line.dayNumber).length === 1
  }

  getMaxDayNumber() {
    return Math.max(...this.lines.map(el => el.dayNumber))
  }

  checkDaysNumberInRow(line) {
    const maxDayNumber = this.getMaxDayNumber()

    if (line.dayNumber > maxDayNumber + 1) {
      line.dayNumber - 1 === maxDayNumber + 1
        ? this.validationErrors.dayNumber.push(`Не заполнен день ${maxDayNumber + 1}`)
        : this.validationErrors.dayNumber.push(`Не заполнены дни ${maxDayNumber + 1} - ${line.dayNumber - 1}`)

      throw new Error()
    }
  }

  checkMissedDays() {
    const maxDayNumber = this.getMaxDayNumber()

    if (maxDayNumber > 1) {
      const allDaysNumbers = Array.from({length: maxDayNumber}, (_, i) => i + 1)

      const allLinesDaysNumbers = new Set()
      this.lines.forEach(line => allLinesDaysNumbers.add(line.dayNumber))

      if (allDaysNumbers.some(el => ![...allLinesDaysNumbers].includes(el))) {
        this.validationErrors.dayNumber.push('Дни занятий должны идти подряд')
        throw new Error()
      }
    }
  }

  isAllowToRemove(line) {
    const maxDayNumber = this.getMaxDayNumber()
    const totalCurrentDayLines = this.lines.filter(el => el.dayNumber === line.dayNumber)

    return maxDayNumber === line.dayNumber || totalCurrentDayLines.length > 1
  }

  findLineIndexInList(line) {
    return this.lines.findIndex(el => el.rowOrder === line.rowOrder)
  }

  addLineToPosition(sourceLine, targetLine, shift = 0) {
    if (this.findLineIndexInList(sourceLine) !== -1) {
      this.lines.splice(this.findLineIndexInList(sourceLine) + shift, 0, targetLine)
      this.setRowOrders()
    }
  }


  addLine(line) {
    this.clearValidationErrors()

    if (this.lines.length > 0) {
      // проверим, чтобы дни шли подряд
      this.checkDaysNumberInRow(line)
      // получим список строк в этот день
      const currentDayLines = this.lines.filter(el => el.dayNumber === line.dayNumber)
      // если есть строки этого дня, то вставим после последней
      if (currentDayLines.length) {
        const lastCurrentDayLine = currentDayLines[currentDayLines.length - 1]

        this.addLineToPosition(lastCurrentDayLine, line, 1)
      } else {
        // не нашли строки в текущий день, значит ищем на день меньше
        const dayBeforeLines = this.lines.filter(el => el.dayNumber === line.dayNumber - 1)
        // если есть строки на день меньше, то вставим после последней
        if (dayBeforeLines.length) {
          const dayBeforeLine = dayBeforeLines[dayBeforeLines.length - 1]

          this.addLineToPosition(dayBeforeLine, line, 1)
        } else {
          // исключение для ситуации, когда в строках отсутствует день с номером 1
          if (line.dayNumber === 1) {
            this.addLineToPosition(this.lines[0], line)
          }
        }
      }
    } else {
      if (line.dayNumber === 1) {
        this.lines.push(line)
        this.setRowOrders()
      } else {
        this.validationErrors.dayNumber.push('Первая строка расписания должна иметь день с номером 1')
        throw new Error()
      }
    }

    eventBus.$emit('closeScheduleTemplateLineDialog')
  }

  editLine(line) {
    const cloneLines = _cloneDeep(this.lines)

    try {
      const sourceLine = this.lines.find(el => el.rowOrder === line.rowOrder)

      // если меняется день строки шаблона, то проверим, чтобы не было пропусков в днях
      if (sourceLine.dayNumber !== line.dayNumber ) {
        this.checkMissedDays(line)
      }

      this.lines.splice(this.findLineIndexInList(line), 1, line)

      eventBus.$emit('closeScheduleTemplateLineDialog')
    } catch (e) {
      this.lines = cloneLines
    }
  }

  dragEditLine(line, rowOrder, setUnderRow) {
    this.lines.splice(this.findLineIndexInList(line), 1)
    this.addLineToPosition({ rowOrder }, line, setUnderRow ? 1 : 0)
  }

  removeLine(line) {
    const index = this.lines.findIndex(el => el.rowOrder === line.rowOrder)
    this.lines.splice(index, 1)

    // this.sortLinesByOrder()
    this.setRowOrders()
  }

  getForAPI() {
    return {
      scheduleTemplateId: this.scheduleTemplateId,
      departmentId: this.departmentId,
      description: this.description,
      lines: this.lines.map(line => line.getForAPI())
    }
  }

  static buildFromAPI(obj) {
    const _this = new ScheduleTemplate()

    if (obj) {
      _this.scheduleTemplateId = obj.scheduleTemplateId
      _this.departmentId = obj.departmentId
      _this.description = obj.description
      _this.linesCount = obj.linesCount || 0
      _this.lines = obj.lines?.map(line => ScheduleTemplateLine.buildFromAPI(line)) || []
    }

    return _this
  }
}


export class ScheduleTemplateLine {
  constructor() {
    this.scheduleTemplateLineId = null
    this.order = null
    this.rowOrder = null
    this.dayNumber = null
    this.academicWork = null
    this.eventPlace = null
    this.chapter = null
    this.name = null
  }

  getForAPI() {
    return {
      scheduleTemplateLineId: this.scheduleTemplateLineId,
      order: this.order || 0,
      rowOrder: this.rowOrder,
      dayNumber: this.dayNumber,
      academicWork: this.academicWork?.getForAPI() || null,
      eventPlace: this.eventPlace?.getForAPI() || null,
      chapter: this.chapter,
      name: this.name,
    }
  }

  static buildFromAPI(obj) {
    const _this = new ScheduleTemplateLine()

    if (obj) {
      _this.scheduleTemplateLineId = obj.scheduleTemplateLineId || null
      _this.order = obj.order
      _this.rowOrder = obj.order
      _this.dayNumber = obj.dayNumber
      _this.academicWork = obj.academicWork && AcademicWork.buildFromAPI(obj.academicWork)
      _this.eventPlace = obj?.eventPlace ? EventPlace.buildFromAPI(obj.eventPlace) : null
      _this.chapter = obj.chapter
      _this.name = obj.name
    }

    return _this
  }
}