<template>
  <div>
    <div class="text-center mt-6" v-if="!dataLoaded">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <div v-show="dataLoaded">
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-card class="table-card">
            <v-data-table
              ref="themesTable"
              class="base-table"
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="cycleAccess.list"
              :items-per-page="-1"
            >
              <template #item.before="{ item }">
                <span>{{ item.before ? 'До даты' : 'После даты' }}</span>
              </template>

              <template #item.dateModeAbsolute="{ item }">
                <span>{{ item.dateModeAbsolute ? 'Абсолютное' : 'Относительное' }}</span>
              </template>

              <template #item.actions="{ item }">
                <v-icon
                  color="btnIcon"
                  class="mr-1 edit-btn"
                  size="22"
                  :title='`Редактирование вкладки "${item.cycleAccessParamType.name}"`'
                  @click="editCycleAccessLine(item)"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>

              <template slot="no-data">
                <div>Таблица пуста</div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <CycleAccessLineDialog
        :dialog="dialog"
        :cycleAccessLine="selectedItem"
        :loading="loading"
        @saveCycleAccess="saveCycleAccess"
        @closeDialog="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { cycleAccessApi } from '@/api'
import _cloneDeep from 'lodash/cloneDeep'
import { CycleAccess } from '@/models'
import CycleAccessLineDialog from '@/components/settings/cycleAccess/dialogs/CycleAccessLine'

export default {
  name: 'CycleAccess',

  metaInfo: {
    title: 'Доступ к данным слушателя'
  },

  components: {
    CycleAccessLineDialog
  },

  async created() {
    await this.getCycleAccess()
    this.dataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Название вкладки', value: 'cycleAccessParamType.name', sortable: false, align: 'left' },
      { text: 'Действие по времени', value: 'before', sortable: false, align: 'left' },
      { text: 'Ограничение по времени', value: 'dateModeAbsolute', sortable: false, align: 'left' },
      { text: 'Относительно даты цикла', value: 'cycleDates', sortable: false, align: 'left' },
      { text: 'Значение', value: 'daysValue', sortable: false, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
    ],
    dialog: false,
    loading: false,
    selectedItem: null,

    cycleAccess: new CycleAccess()
  }),

  methods: {
    async getCycleAccess() {
      try {
        const data = await cycleAccessApi.getCycleAccess()
        this.cycleAccess.buildFromAPI(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveCycleAccess(item) {
      const cycleAccessClone = _cloneDeep(this.cycleAccess)

      try {
        this.loading = true

        this.cycleAccess.updateCycleAccessLine(item)
        await cycleAccessApi.saveCycleAccess(this.cycleAccess.getForAPI())
        this.closeDialog()
      } catch (e) {
        this.cycleAccess = cycleAccessClone // вернём старую версию, если не сохранилось
        this.showErrorMessage(e, 'Ошибка сохранения')
      } finally {
        this.loading = false
      }
    },

    editCycleAccessLine(item) {
      this.selectedItem = item
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>