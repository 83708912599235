<template>
  <div>
    <div class="text-center" v-if="!departmentDataLoaded || !scheduleTemplatesDataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-if="departmentDataLoaded">
      <div v-show="scheduleTemplatesDataLoaded">
        <PageTitle title="Список шаблонов расписания" />

        <v-row class="my-0">
          <v-col cols="12" class="pt-0 pb-5">
            <h4 class="department">{{ departmentName }}</h4>
          </v-col>
        </v-row>

        <ScheduleTemplatesTable
          @dataLoaded="scheduleTemplatesDataLoaded = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'
import ScheduleTemplatesTable from '@/components/department/scheduleTemplates/Table'

export default {
  name: 'ScheduleTemplates',

  metaInfo: {
    title: 'Список шаблонов расписания'
  },

  components: {
    PageTitle,
    ScheduleTemplatesTable
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.departmentDataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    departmentDataLoaded: false,
    scheduleTemplatesDataLoaded: false
  }),

  computed: {
    ...mapGetters('department', ['departmentName'])
  },

  methods: {
    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>